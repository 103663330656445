import sideToggleOverlay from "../../functions/sideToggleOverlay";
import IconAngleLeft from "../icons/iconAngleLeft";
import configs from "../../functions/configs";
import postData from "../../functions/postData";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import { useContext } from "react";
import { UserContext } from "../..";
import clearCache from "../../functions/clearCache";
import capitalizeFirstLetter from "../../functions/capitalizeFirstLetter";
import setCookie from "../../functions/setCookie";
import getInitials from "../../functions/getInitials";
import getFirstName from "../../functions/getFirstName";

const SwitchAccountSideDrawer = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const closeOverlay = (e) => {
        sideToggleOverlay('#switch-account-side-drawer', e);
    }
    
    const backToAccountDrawer = (e) => {
        sideToggleOverlay('#switch-account-side-drawer', e);
        setTimeout(()=> {
            sideToggleOverlay('#my-account-side-drawer', e);
        }, 200);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    const switchAccount = (e) => {
        e.preventDefault();
        clearCache();
        toggleLoadingOverlay();
        let id = e.currentTarget.attributes.dataid.value;
        postData(conf.baseAPI + '/user/switch-account.php', 'id=' + id).then((res) => {
            toggleLoadingOverlay();
            if (typeof res.description !== 'undefined') {
                launchErrorOverlay(res.description, '');
                return '';
            }
            if (typeof res.error !== 'undefined') {
                launchErrorOverlay(res.error, '');
                return '';
            }
            if (typeof res.bpl_catalog !== 'undefined') {
                user.data.session = res;

                let firstName = '';
                try {
                    firstName = user.data.session.bpl_catalog.name.replace(/.+,/, '');
                    firstName = firstName.trim();
                    firstName = firstName.toLowerCase();
                    firstName = capitalizeFirstLetter(firstName);
                    firstName = firstName.replace(/ .+/, '');
                } catch {}

                launchSuccessOverlay('Switched to ' + firstName + '\'s acount.');
                closeOverlay();
                setCookie('bplswitchedcheckouts', Date.now());
                setCookie('bplswitchedholds', Date.now());
                setCookie('bplswitchedbooklists', Date.now());
                setCookie('bplswitchedhistory', Date.now());
                clearCache();
                props.setLoginState('account added ' + Date.now());
            }
        });
    }

    const Name = (props) => {
        let initials = getInitials(props.data.name);
        let firstName = getFirstName(props.data.name);
        let libraryCardNum = props.data.barcode;
        try {
            return (
                <button dataid={props.data.id} onClick={switchAccount} className="name-container switch-accounts">
                    <div className="initials-circle-profile bold eighteen-point">{initials}</div>
                    <div>
                        <div className="bold main-color eighteen-point">{firstName}</div>
                        <div className="main-color">{libraryCardNum}</div>
                    </div>
                </button>
            )
        } catch {}
    }

    const Names = (props) => {
        try {
            return user.data.session.bpl_catalog.accounts.map(key => <Name key={key.id} data={key}/>)
        } catch {}
    }

    return (
        <div onClick={containerClick} id="switch-account-side-drawer" className='overlay-container side-slide'>
            <div className='overlay-body side-slide-right'>
                <div className="overlay-top-button-container">
                    <button aria-label="Close dialog" onClick={backToAccountDrawer} ><IconAngleLeft /></button>
                    
                </div>
               <div className="bold main-color eighteen-point">Switch Accounts</div>
               <div className="switch-accounts-names-container">
                <Names />
               </div>
            </div>
        </div>
    )
}

export default SwitchAccountSideDrawer;