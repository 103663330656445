import { useEffect, useState } from "react";
import configs from "../../functions/configs";
import postData from "../../functions/postData";

const Spellcheck = (props) => {

  const url = new URL(window.location.href);
  const conf = configs();

  const [spellcheckState, setSpellcheckState] = useState([]);

  let letsGo = false;

  try {
    if (typeof props.solrData.grouped !== 'undefined') {
      if (props.solrData.grouped.ss_grouping.matches === 0) {
        letsGo = true;
      }
    }
    if (typeof props.solrData.response !== 'undefined') {
      if (props.solrData.response.docs.length === 0) {
        letsGo = true;
      }
    }
    if (url.searchParams.get('search') === null) {
      letsGo = false;
    }
    if (url.searchParams.size > 2) {
      letsGo = false;
    }
  } catch {}
  

  useEffect(()=>{
    if (letsGo) {
      postData(conf.baseAPI + '/solr/spellcheck.php', 'query=' + url.searchParams.get('search')).then(res => {
        setSpellcheckState(res);
        console.log(res);
      });
    }
  },[props.solrData]);
  
  
  if (letsGo === false) {
    return '';
  }

  try {
    
    if (spellcheckState.length > 0) {
      let link ="/?search=" + spellcheckState[0].suggestion;
      if (spellcheckState[0].suggestion.toLowerCase().trim() === url.searchParams.get('search').toLowerCase().trim()) {
        return '';
      }
      return (
        <div className="spellcheck-container">
          Did you mean <a href={link}>{spellcheckState[0].suggestion}</a>?
        </div>
      )
    } else {
      return '';
    }
    
  } catch { return ''}
};

export default Spellcheck;