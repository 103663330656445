import CompasssImg from "../icons/compassImg";
import AccountIcon from "../icons/accountIcon";
import CheckoutBookIcon from "../icons/checkoutBookIcon";
import toggleOverlay from "../../functions/toggleOverlay";
import DigitalCardIcon from "../icons/digitalCardIcon";
import IconHouse from "../icons/iconHouse";
import IconHouseThinner from "../icons/iconHouseThinner";
import barcodeScanner from "../../functions/barcodeScanner";
import { useContext } from "react";
import { UserContext } from "../..";
import setCookie from "../../functions/setCookie";
import MagnifyingGlassIcon from "../icons/magnifyingGlassIcon";

const BottomNav = (props) => {

    const url = new URL(window.location.href);

    const user = useContext(UserContext);

    const discoverNavBarClick = (e) => {
        window.location.href = "/";
    }

    const accountNavBarClick = (e) => {
        window.location.href = "/my-account";
    }

    const accountCheckoutClick = (e) => {
        try {
            if (user.data.isSession) {
                toggleOverlay("#self-checkout-overlay", e);
                setTimeout(()=>{
                    document.querySelector('#launch-barcode-scanner').click();
                }, 300);
                
            } else {
                setCookie('opacLC','#self-checkout-bottom-nav', 1);
                toggleOverlay('#login-overlay', e);
            }
        } catch (err) {
            setCookie('opacLC','#self-checkout-bottom-nav', 1);
            toggleOverlay('#login-overlay', e);
            console.log(err);
        }
    }

    const digitalCardClick = (e) => {
        try {
            if (user.data.isSession) {
                toggleOverlay("#digital-card-overlay", e);
            } else {
                setCookie('opacLC','#digital-card-bottom-nav', 1);
                toggleOverlay('#login-overlay', e);
            }
        } catch {
            setCookie('opacLC','#digital-card-bottom-nav', 1);
            toggleOverlay('#login-overlay', e);
        }
    }

    const purpleFill = '#9C2273';
    const blackFill = '#2C2C2C';

    let discoverFill = blackFill;
    const discoverTextStyle = {color: blackFill};

    if (url.pathname === '/' && url.search === '') {
        discoverFill = purpleFill;
        discoverTextStyle.color = purpleFill;
    }

    let accountFill = blackFill;
    const accountTextStyle = {color: blackFill};
    if (url.pathname === '/my-account' || url.pathname === '/my-account.html') {
        accountFill = purpleFill;
        accountTextStyle.color = purpleFill;
    }

    const iOS = () => {
        try {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        } catch {}
    }

    let paddingBottom = {};

    if (iOS()) {
        //paddingBottom = {paddingBottom: '22px'};
    } 

    return (
        <div style={paddingBottom} id="fixed-bottom-nav">
            <button onClick={discoverNavBarClick} className="bottom-nav-btn">
                <MagnifyingGlassIcon height="22" fillColor={discoverFill}></MagnifyingGlassIcon>
                <div style={discoverTextStyle}>Search</div>
            </button>
            <button onClick={accountNavBarClick} className="bottom-nav-btn">
                <AccountIcon fillColor={accountFill}></AccountIcon>
                <div style={accountTextStyle}>Account</div>
            </button>
            <button id='self-checkout-bottom-nav' onClick={accountCheckoutClick} className="bottom-nav-btn">
                <CheckoutBookIcon></CheckoutBookIcon>
                <div>Checkout</div>
            </button>
            <button id='digital-card-bottom-nav' onClick={digitalCardClick} className="bottom-nav-btn">
                <DigitalCardIcon></DigitalCardIcon>
                <div><span id="digital-card-text-to-hide">Digital </span>Card</div>
            </button>
        </div>
    )
}

export default BottomNav;