
const PrimaryItemDetails = (props) => {
  try {
    let callNumber = '';
    let publisher = '';
    let language = '';
    let targetAge = '';
    let previewLink = '';
    
    
    try {
      if (props.data.call_number) {
        callNumber = '' + props.data.call_number;
      }
      if (props.data.publisher) {
        publisher = props.data.publisher;
      }
      if (props.data.language[0]) {
        language = props.data.language[0];
      }
      if (props.data.target_age[0]) {
        targetAge = props.data.target_age[0];
      }
      if (props.data.esampleurl) {
        previewLink = props.data.esampleurl;
      }
    } catch {}

    const CallNumber = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold notranslate">Call Number: </span><span>{props.text}</span></div>
      )
    }

    const Publisher = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold">Publisher: </span><span>{props.text}</span></div>
      )
    }

    const Language = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold">Language: </span><span>{props.text}</span></div>
      )
    }

    const TargetAge = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold">Target Age: </span><span>{props.text}</span></div>
      )
    }

    

    if (callNumber === '' && publisher === '' && language === '' && targetAge === '') {
      return '';
    }

    return (
      <div>
        <h3>Details</h3>
        <div className="primary-item-details-container">
          <CallNumber text={callNumber}></CallNumber>
          <Publisher text={publisher}></Publisher>
          <Language text={language}></Language>
          <TargetAge text={targetAge}></TargetAge>
        </div>
      </div>
      
    )
  } catch {}
};

export default PrimaryItemDetails;