import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import configs from "../../functions/configs";
import { useContext } from "react";
import { UserContext } from "../..";

const EditNamesOverlay = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const updateNames = (e) => {

        e.preventDefault();

        document.querySelector('.username-invalid-format').classList.add('hide');
        document.querySelector('.username-taken-msg').classList.add('hide');

        let usernameVal = document.querySelector('#username-input').value;
        
        if (usernameVal !== '') {
            if (usernameVal.match(/^[a-z0-9]+$/i) === null) {
                document.querySelector('.username-invalid-format').classList.remove('hide');
                return '';
            }

            if (usernameVal.length < 6) {
                document.querySelector('.username-invalid-format').classList.remove('hide');
                return '';
            }

            if (usernameVal.length > 15) {
                document.querySelector('.username-invalid-format').classList.remove('hide');
                return '';
            }
            
        }

        let postString = 'username=' + encodeURIComponent(usernameVal);

        toggleLoadingOverlay();

        postData(conf.baseAPI + "/sierra/sierra-update-username.php", postString).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            
            if (res === null) { // success
                document.querySelector('#update-state-my-account-edit-container').click();
                launchSuccessOverlay("Username updated", "");
                document.querySelector('#edit-names-overlay button.close-overlay').click();
            } else if (typeof res.sessionExpired !== 'undefined') {
                
                document.querySelector('#edit-names-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                document.querySelector('#edit-names-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                if (res.message === 'Username already taken') {
                    document.querySelector('.username-taken-msg').classList.remove('hide');
                    document.querySelector('#edit-names-overlay button.close-overlay').focus();
                } else {
                    document.querySelector('#edit-names-overlay button.close-overlay').click();
                    launchErrorOverlay(res.message, '');
                }
                
            }
        });
    }

    const closeOverlay = (e) => {
        toggleOverlay("#edit-names-overlay");
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const marginTop = {marginTop: '10px'};

    let preferredName = '';
    let legalName = '';
    let userName = '';

    try {

        if (user.data.session.bpl_catalog.legalname === '') {
            legalName = user.data.session.bpl_catalog.name;
        } else {
            preferredName = user.data.session.bpl_catalog.replace(/.+, /, '');
            legalName = user.data.session.bpl_catalog.legalname
        }

        if (user.data.session.bpl_catalog.altid !== '') {
            userName = user.data.session.bpl_catalog.altid;
        }
    } catch {}


    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="edit-names-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Edit names</h3>
                
                <div className="hide username-taken-msg">Sorry that username is already taken</div>

                <div className="hide username-invalid-format">Invalid username format</div>

                <form onSubmit={updateNames} id="update-names-form">
                    <div>
                        <div>You can update your legal and preferred name by visiting us at <a target="_blank" href="https://www.bklynlibrary.org/locations">one of our branches</a> or calling us at <a href="tel:+17189687275">718.968.7275</a>.</div>
                        <div style={marginTop}>
                            <label htmlFor="preferred-name-input">Preferred Name</label>
                            <input id="preferred-name-input" disabled autoComplete="off" value={preferredName} placeholder="" type="text"></input>
                        </div>
                        <br></br>
                        <div>
                            <label htmlFor="legal-name-input">Legal Name</label>
                            <input id="legal-name-input" disabled autoComplete="off" value={legalName} placeholder="" type="text"></input>
                        </div>
                        <br></br>
                        <div>
                            <label htmlFor="username-input">Username</label>
                            <input id="username-input" autoComplete="off" placeholder="" defaultValue={userName} type="text"></input>
                        </div>
                        <div style={marginTop}>Usernames can be used as an alternative to your barcode at login. They must be between 6-15 characters. No special characters are allowed.</div>
                        <div>
                            <button type="submit">Update</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
} 

export default EditNamesOverlay;