import configs from "./configs";

const returnBestImage = (data) => {

  const conf = configs();

  let url = '';

  try {
    
    if (data.length > 0) { // receiving a grouping array

      // First try eBook, then eAudio, then go to content cafe
      for (var i = 0; i < data.length; i++) {
        if (data[i].material_type === "eBook") {
          if (typeof data[i].digital_cover_image !== "undefined" && data[i].digital_cover_image !== "") {
            url = data[i].digital_cover_image;
          }
        }
      }
  
      if (url === "") {
        for (var i = 0; i < data.length; i++) {
          if (data[i].material_type === "eAudiobook") {
            if (typeof data[i].digital_cover_image !== "undefined" && data[i].digital_cover_image !== "") {
              url = data[i].digital_cover_image;
            }
          }
        }
      }
  
      if (url === "") {
        for (var i = 0; i < data.length; i++) {
          if (typeof data[i].digital_cover_image !== 'undefined') {
            url = data[i].digital_cover_image;
          }
        }
      }

      if (url === "") {
        for (var i = 0; i < data.length; i++) {
          if (typeof data[i].isbn !== 'undefined') {
            url = conf.vendorImageURL + data[i].isbn[0];
            break;
          }
        }
      }

      if (url === "") {
        try {
          if (typeof data[0].sm_bib_varfields !== 'undefined') {
            for (let i = 0; i < data[0].sm_bib_varfields.length; i++) {
              if (data[0].sm_bib_varfields[i].match(/^962 /) !== null) {
                if (data[0].sm_bib_varfields[i].match(/png/i) !== null || data[0].sm_bib_varfields[i].match(/jpeg/i) !== null || data[0].sm_bib_varfields[i].match(/jpg/i) !== null) {
                  let hash = data[0].sm_bib_varfields[i].match(/:\d\d\d\d\d\d:/)[0];
                  hash = hash.replace(/:/g, '');
                  let type = 'png';
                  if (data[0].sm_bib_varfields[i].toLowerCase().indexOf('jpeg') > -1) {
                    type = 'jpeg';
                  }
                  if (data[0].sm_bib_varfields[i].toLowerCase().indexOf('jpg') > -1) {
                    type = 'jpg';
                  }
                  url = "https://catalog.brooklynpubliclibrary.org/articles/" + data[0].id +"." + hash + "/1." + type;
                  break;
                }
              }
            }
          }
        } catch {}
      }
  
    } else { // receiving a single doc
  
      if (typeof data.digital_cover_image !== 'undefined') {
        url = data.digital_cover_image;
      } else {

        if (typeof data.isbn !== 'undefined') {
          if (typeof data.isbn[0] !== 'undefined') {
            url = conf.vendorImageURL + data.isbn[0];
          }
        }

        if (typeof data.sm_bib_varfields !== 'undefined') {
          for (let i = 0; i < data.sm_bib_varfields.length; i++) {
            if (data.sm_bib_varfields[i].match(/^962 /) !== null) {
              if (data.sm_bib_varfields[i].match(/png/i) !== null || data.sm_bib_varfields[i].match(/jpeg/i) !== null || data.sm_bib_varfields[i].match(/jpg/i) !== null) {
                let hash = data.sm_bib_varfields[i].match(/:\d\d\d\d\d\d:/)[0];
                hash = hash.replace(/:/g, '');
                let type = 'png';
                if (data.sm_bib_varfields[i].toLowerCase().indexOf('jpeg') > -1) {
                  type = 'jpeg';
                }
                if (data.sm_bib_varfields[i].toLowerCase().indexOf('jpg') > -1) {
                  type = 'jpg';
                }
                url = "https://catalog.brooklynpubliclibrary.org/articles/" + data.id +"." + hash + "/1." + type;
                break;
              }
            }
          }
        }


      }
      
    }

  } catch {}
  
  return url;

};

export default returnBestImage;