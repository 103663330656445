import React from 'react';
import ReactDOM from 'react-dom/client';
import {createContext} from "react";
import SearchApp from './searchPage';
import ItemApp from './itemPage';
import AccountApp from './accountPage';
import PinApp from "./pinPage";
import reportWebVitals from './reportWebVitals';
import postData from "./functions/postData";
import getData from "./functions/getData";
import configs from './functions/configs';
import clearCache from "./functions/clearCache";
import ReactGA from 'react-ga4';
import { StrichSDK } from '@pixelverse/strichjs-sdk';
ReactGA.initialize("G-D9K81NMJSQ");

export const UserContext = createContext();

const userData = {};

const conf = configs();
/*
try {
  //StrichSDK.initialize(conf.strichKey).then(() => {console.log('STRICH SDK initialized')});
} catch {}
*/

if (typeof navigator.serviceWorker !== 'undefined') {
  navigator.serviceWorker.register('sw.js?v=1.12'); // Register service worker. Update version to avoid cache
}

try {
  if (window.navigator.onLine) { // if online check to update cache

    let lastClearCache = localStorage.getItem('last_cc');
    if (lastClearCache === null) {
      localStorage.setItem('last_cc', Date.now());
      lastClearCache = Date.now();
    }

    let cachePeriod = 900000; // 900 seconds or 15 minutes
    if ((parseInt(lastClearCache) + cachePeriod) < Date.now()) {
      clearCache();
      localStorage.setItem('last_cc', Date.now());
    }
  }
} catch {
  clearCache();// always clear cache if can't access local storage or something crazy
}

if (window.location.href.indexOf('/my-account') === -1) {
  getData(conf.baseAPI + "/user/check-session.php", "").then( data => {
    userData.data = data;
    try {
      delete userData.data.session.bpl_catalog.pin;
      delete userData.data.session.bpl_catalog.overdrive_api_key;
      if (typeof userData.data.session.bpl_catalog.accounts !== 'undefined') {
        for (let i = 0; i < userData.data.session.bpl_catalog.accounts.length; i++) {
          delete userData.data.session.bpl_catalog.accounts[i].pin;
        }
      }
    } catch {}
  });
}

if (window.location.href.indexOf('/item') > -1) {
  if (window.location.href.indexOf('/item.html') === -1 && window.location.href.indexOf('localhost') > -1) {
    window.location.href = window.location.href.replace('/item', '/item.html');
  }
  const root = ReactDOM.createRoot(document.getElementById('item-root'));
  root.render(
    <React.StrictMode>
      <UserContext.Provider value={userData}>
        <ItemApp />
      </UserContext.Provider>
    </React.StrictMode>
  );
} else if (window.location.href.indexOf('/my-account') > -1) {
  if (window.location.href.indexOf('/my-account.html') === -1 && window.location.href.indexOf('localhost') > -1) {
    window.location.href = window.location.href.replace('/my-account', '/my-account.html');
  }
  const root = ReactDOM.createRoot(document.getElementById('account-root'));
  root.render(
    <React.StrictMode>
      <UserContext.Provider value={userData}>
        <AccountApp />
      </UserContext.Provider>
    </React.StrictMode>
  );
} else if (window.location.href.indexOf('/pin-reset') > -1) {
  if (window.location.href.indexOf('/pin-reset.html') === -1 && window.location.href.indexOf('localhost') > -1) {
    window.location.href = window.location.href.replace('/pin-reset', '/pin-reset.html');
  }
  const root = ReactDOM.createRoot(document.getElementById('pin-root'));
  root.render(
    <React.StrictMode>
      <UserContext.Provider value={userData}>
        <PinApp />
      </UserContext.Provider>
    </React.StrictMode>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <UserContext.Provider value={userData}>
        <SearchApp />
      </UserContext.Provider>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

/*
try {
  const loginLogoutButton = document.querySelector('.login-logout-button');
  loginLogoutButton.addEventListener("click", () => {
    clearCache();
  });
} catch {}
 */

// alerts

/*
const div = document.createElement("div");
div.innerHTML = '<div class="alert-message">Due to system maintenance, placing holds is unavailable until 12pm Wednesday, August 14th. We apologize for any inconvenience.</div>';
document.body.prepend(div);
*/

// Gymnastics with Google Translate selectbox for iOS testers..

try {
  setTimeout(()=>{
    const translateSelectbox = document.querySelector('.gtranslate_wrapper select');
    
    try {
      if (translateSelectbox.length > 0) {
        const translateIcon = document.querySelector('.gtranslate_wrapper svg');
        translateIcon.style.display = 'block';
      }
    } catch {}

    try {
      if (window.navigator.userAgent.indexOf('BKLYN iOS App') > -1) {
        document.querySelector('.gtranslate_wrapper').style.display = "none";
        document.querySelector('#header-homepage-link-container').style.display = "none";
      }
    } catch {}
    
    
  }, 100);

  try {
    if (window.navigator.userAgent.indexOf('BKLYN iOS App') > -1) {
      document.querySelector('.gtranslate_wrapper').style.display = "none";
      document.querySelector('#header-homepage-link-container').style.display = "none";
    }
  } catch {}
  
} catch {}