import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import configs from "../../functions/configs";
import { useContext, useState } from "react";
import { UserContext } from "../..";

const EditContactInfoOverlay = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const updateContactInfo = (e) => {

        e.preventDefault();

        document.querySelector('.phone-incorrect-format').classList.add('hide');

        let emailVal = document.querySelector('#email-input').value.trim();
        let noticeVal = document.querySelector('#notice-preference-select').value;
        let libraryVal = document.querySelector('#home-library-select').value;
        let phoneVal = document.querySelector('#phone-input').value.trim();

        if (phoneVal.match(/^\d\d\d\-\d\d\d\-\d\d\d\d$/) === null) {
            document.querySelector('.phone-incorrect-format').classList.remove('hide');
            return '';
        }

        let postString = 'email=' + encodeURIComponent(emailVal);
        postString += '&notice=' + noticeVal;
        postString += '&library=' + libraryVal;
        postString += '&phone=' + encodeURIComponent(phoneVal);

        toggleLoadingOverlay();

        postData(conf.baseAPI + "/sierra/sierra-update-contact-info.php", postString).then((res) => {

            console.log(res);

            toggleLoadingOverlay();
            
            if (res === null) { // success
                document.querySelector('#update-state-my-account-edit-container').click();
                launchSuccessOverlay("Contact information updated", "");
                document.querySelector('#edit-contact-info-overlay button.close-overlay').click();
            } else if (typeof res.sessionExpired !== 'undefined') {
                document.querySelector('#edit-contact-info-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                document.querySelector('#edit-contact-info-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                document.querySelector('#edit-contact-info-overlay button.close-overlay').click();
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const closeOverlay = (e) => {
        toggleOverlay("#edit-contact-info-overlay");
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const marginTop = {marginTop: '15px'};

    let defaultEmail = '';
    try {
        defaultEmail = user.data.session.bpl_catalog.email;
    } catch {}

    let defaultPhone = '';
    try {
        defaultPhone = user.data.session.bpl_catalog.phone;
    } catch {}

    let defaultNoticePreference = '';
    try {
        defaultNoticePreference = user.data.session.bpl_catalog.notice_pref;
        if (defaultNoticePreference === 'email') {
            document.querySelector('#email-input').setAttribute('required', 'required');
            document.querySelector('#phone-input').removeAttribute('required');
        } else {
            document.querySelector('#phone-input').setAttribute('required', 'required');
            document.querySelector('#email-input').removeAttribute('required');
        }
    } catch {}

    

    const HomeLibraryOptions = (props) => {
        let defaultHomeLibrary = '01';
        try {
            defaultHomeLibrary = user.data.session.bpl_catalog.home_library;
            //console.log(defaultHomeLibrary);
        } catch {}

        const Option = (props) => {
            /*
            if (defaultHomeLibrary === props.data.code) {
                return (
                    <option selected value={props.data.code}>{props.data.name}</option>
                )
            }
                */
            return (
                <option value={props.data.code}>{props.data.name}</option>
            )
        }

        const MapLibraryOptions = () => {
            return (
                conf.allHomeBranchLocations.map(item=> <Option key={item.code} data={item}></Option>)
            )
        }
        
        return (
            <div>
                <label htmlFor="home-library-select">Home Library</label>
                <select defaultValue={defaultHomeLibrary} id="home-library-select">
                    <MapLibraryOptions></MapLibraryOptions>
                </select>
            </div>
            
        )
        
    }

    const updateRequirements = (e) => {
        if (e.currentTarget.value === 'email') {
            document.querySelector('#email-input').setAttribute('required', 'required');
            document.querySelector('#phone-input').removeAttribute('required');
        } else {
            document.querySelector('#phone-input').setAttribute('required', 'required');
            document.querySelector('#email-input').removeAttribute('required');
        }
    }

    const NoticeOptions = (props) => {

        const noticeArray = [
            {val: "email", label: "Email"},
            {val: "text", label: "Text"},
            {val: "telephone", label: "Telephone"}
        ]

        const Option = (props) => {
            if (defaultNoticePreference === props.data.val) {
                return (
                    <option selected value={props.data.val}>{props.data.label}</option>
                )
            }
            return (
                <option value={props.data.val}>{props.data.label}</option>
            )
        }
        return (
                noticeArray.map(item => <Option key={item.val} data={item}></Option>)
        )
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="edit-contact-info-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Edit Contact Info</h3>

                <form onSubmit={updateContactInfo} id="update-contact-info-form">
                    <div>
                        <div>
                            <label htmlFor="notice-preference-select">Notice Preference</label>
                            <select onChange={updateRequirements} defaultValue={defaultNoticePreference} id="notice-preference-select">
                                <NoticeOptions></NoticeOptions>
                                
                            
                            </select>
                        </div>

                        <div style={marginTop}>
                            <label htmlFor="email-input">Email</label>
                            <input defaultValue={defaultEmail} id="email-input" type="email"></input>
                        </div>

                        <div style={marginTop} className="phone-incorrect-format hide">
                            Please enter your phone number in format XXX-XXX-XXXX
                        </div>

                        <div style={marginTop}>
                            <label htmlFor="phone-input">Phone</label>
                            <input placeholder="xxx-xxx-xxxx" defaultValue={defaultPhone} id="phone-input" type="text"></input>
                        </div>

                        <div style={marginTop}>
                            
                            <HomeLibraryOptions></HomeLibraryOptions>
                        </div>

                        <div>
                            <button type="submit">Update</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
} 

export default EditContactInfoOverlay;