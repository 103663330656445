import { useEffect, useState } from "react";
import configs from "../../functions/configs";
import postData from "../../functions/postData";

let grabbedData = false;
const RelatedBooklists = (props) => {

  const conf = configs();

  const url = new URL(window.location);

  let group_id = props.data.ss_grouping;

  const [booklists, setBooklists] = useState({});

  useEffect(()=> {
    if (typeof group_id === 'string') {
      if (!booklists.length) {
        if (grabbedData === false) {
          postData(conf.baseAPI + '/solr/get-related-booklists', 'grouping=' + group_id).then(res => {
            console.log(res);
            grabbedData = true;
            setBooklists(res.response.docs);
          });
        }
      }
    }
  }, [url]);

  const Booklist = (props) => {
    try {
      let link = '/?booklist=' + props.data.item_id;
      const firstItem = JSON.parse(props.data.sm_field_catalog_item[0]);
      let booklistImage = firstItem.img;
      return (
        <a href={link} className="related-booklist">
          <div className="flex">
            <div>
              <img src={booklistImage}></img>
            </div>
            <div>
              <div className="dark-gray bold">STAFF SELECTIONS</div>
              <div className="related-booklist-title no-color">{props.data.ts_title}</div>
            </div>
          </div>
        </a>
      )
    } catch {}
  }

  const Booklists = () => {
    try {
      return booklists.map(item => <Booklist key={item.id} data={item} />);
    } catch {}
  }

  try {
    if (booklists.length > 0) {
      return (
        <div className="order-information">
          <h3>Related Booklists</h3>
          <div className="related-booklists-container">
          <Booklists />
          </div>
        </div>
      )
    }
  } catch {}
};

export default RelatedBooklists;