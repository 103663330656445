import './App.css';
import { useState } from "react";
import Footer from "./components/shared/footer";
import configs from "./functions/configs";
import OverlayContainer from "./components/overlays/overlayContainer";
import BottomNav from "./components/shared/bottomNav";
import toggleLoadingOverlay from './functions/toggleLoadingOverlay';
import postData from './functions/postData';
import launchErrorOverlay from './functions/launchErrorOverlay';
import launchSuccessOverlay from './functions/launchSuccessOverlay';
import NotOnlineScreen from './components/shared/notOnlineScreen';


function PinApp() {

  const conf = configs();

  const [pinPageState, setPinPageState] = useState('');
  const [noticePreferenceInfo, setNoticePreferenceInfo] = useState({});
  const [userInfo, setUserInfo] = useState('');
  const [loginState, setLoginState] = useState('');

  const url = new URL(window.location);

  const isOnline = window.navigator.onLine;

  if (!isOnline) {
    return (
      <NotOnlineScreen />
    )
  }

  const submitBarcode = (e) => {
    e.preventDefault();
    toggleLoadingOverlay();

    try {
      document.querySelector('a').focus();
    } catch {}

    let barcodeVal = encodeURIComponent(document.querySelector('#barcode-entry-for-pin-reset').value.trim());

    console.log(barcodeVal);

    postData(conf.baseAPI + '/user/email-pin-reset.php', 'barcode=' + barcodeVal).then((res) => {
      console.log(res);
      toggleLoadingOverlay();
      if (typeof res.success !== 'undefined') {
        setNoticePreferenceInfo(res);
        setPinPageState('sent');
        launchSuccessOverlay('', '');
      } else if (typeof res.error !== 'undefined') {
        launchErrorOverlay(res.error, '');
      } else {
        launchErrorOverlay('Could not find barcode/username. Try again, or contact us.');
      }
    });
  }

  const BarcodeForm = () => {
    return (
      <div>
        <h1>Reset your PIN</h1>
        <div>A link will be emailed or texted to you, based on your notification preference.</div>
        <form onSubmit={submitBarcode} id="request-pin-reset-email">
          <div>
            <label htmlFor='barcode-entry-for-pin-reset'>Barcode or Username</label>
            <input required autoComplete='off' type="text" id="barcode-entry-for-pin-reset"></input>
          </div>
          <button type="submit">Send reset link</button>
        </form>
      </div>
    )
  }

  const submitPinReset = (e) => {
    e.preventDefault();
    const newPin = document.querySelector('#new-pin').value.trim();
    const confirmNewPin = document.querySelector('#confirm-new-pin').value.trim();
    const barcode = document.querySelector('#pin-reset-barcode').value.trim();
    if (newPin !== confirmNewPin) {
      document.querySelector('#no-pin-match-msg').classList.remove('hide');
      return '';
    }

    let dataString = "pin=" + encodeURIComponent(newPin) + "&barcode=" + encodeURIComponent(barcode);

    toggleLoadingOverlay();
    document.querySelector('a').focus();
    postData(conf.baseAPI + "/user/reset-pin.php", dataString).then(res => {
      toggleLoadingOverlay();
      launchSuccessOverlay('', '');
      console.log(res);
      setPinPageState('successfully updated')
    });
  }

  const PinForm = (props) => {
    try {

      if (typeof userInfo.error !== 'undefined') {
        return (
          <div>
            <h1>Reset your PIN</h1>
            <div>Your code has expired. Please <a href="/pin-reset">fill out the reset form again</a>.</div>
          </div>
        )
      }

      const noMatchStyle = {
        color: "darkRed",
        marginBottom: "15px"
      }

      return (
        <div>
          <h1>Reset your PIN</h1>
          <div style={noMatchStyle} className='hide' id="no-pin-match-msg">PIN values do not match</div>
          <form onSubmit={submitPinReset} id="request-pin-reset-email">
            <div>
              <label htmlFor='pin-reset-barcode'>Barcode or Username</label>
              <input required disabled value={userInfo.barcode} autoComplete='off' type="text" id="pin-reset-barcode"></input>
            </div>
            <div>
              <label htmlFor='new-pin'>New PIN</label>
              <input required autoComplete='off' type="password" id="new-pin"></input>
            </div>
            <div>
              <label htmlFor='confirm-new-pin'>Confirm New PIN</label>
              <input required autoComplete='off' type="password" id="confirm-new-pin"></input>
            </div>
            <button type="submit">Reset PIN</button>
          </form>
        </div>
      )

    } catch {}
    
  }

  const ResetPinApp = () => {
    if (pinPageState === 'sent') {
      return (
        <div>
          <h1>Reset your PIN</h1>
          <div>{noticePreferenceInfo.success} You have 3 hours to use that link.</div>
        </div>
      )
    }

    if (pinPageState === 'successfully updated') {
      return (
        <div>
          <h1>Reset your PIN</h1>
          <div>Your PIN is reset! <a href="/my-account">Log in</a> to My Account with your new PIN.</div>
        </div>
      )
    }

    if (url.searchParams.get('code') === null) {
      return (
        <BarcodeForm></BarcodeForm>
      )
    } else {
      if (userInfo === '') {
        postData(conf.baseAPI + "/user/get-info-from-reset-code.php", "code=" + url.searchParams.get('code')).then(res => {
          console.log(res);
          setUserInfo(res);
        });
      }
      return (
        <PinForm></PinForm>
      )
    }
  }


  return (
    <div className="App pin-page">  
      <div className='main-app-container'>
        <ResetPinApp></ResetPinApp>
      </div>
      <Footer></Footer>
      <BottomNav></BottomNav>
      <OverlayContainer setLoginState={setLoginState}></OverlayContainer>
    </div>
  )
}



export default PinApp;