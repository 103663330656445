import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from '../icons/thinX';
import { useContext, useState } from "react";
import returnBestImage from "../../functions/returnBestImage";
import postData from "../../functions/postData";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import configs from "../../functions/configs";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import {UserContext} from "../../";
import IconRobot from "../icons/iconRobot";
import getCookie from "../../functions/getCookie";

function RebalancingClaimOverlay (props) {

  const [rebalancingItems, setRebalancingItems] = useState({});
  
  const conf = configs();

  const user = useContext(UserContext);

  const closeOverlay = (e) => {
    setRebalancingItems({});
    toggleOverlay('#rebalacing-claim-overlay');
  }

  const containerClick = (e) => {
    if (e.target.classList.contains('overlay-container')) {
        closeOverlay();
    }
  }

  let touchstartY = 0;
  let touchendY = 0;
  const handleTouchStart = (event) => {
      touchstartY = event.touches[0].screenY;
  }
  const handleTouchMove = (event) => {
      touchendY = event.touches[0].screenY;
  }
  const handleTouchEnd = (event) => {
      if (touchendY > (touchstartY + 300)) {
          closeOverlay();
      }
      touchstartY = 0;
      touchendY = 0;
  }

  const updateRebalancingItems = (e) => {
    try {
      let bibs = getCookie('rebalancingBibs');
      postData(conf.baseAPI + "/solr/get-rebalancing-items.php", 'bibs=' + encodeURIComponent(bibs)).then(res => {
        console.log(res);
        setRebalancingItems(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  const RebalancingItemsContainer = () => {

    const RebalancingItem = (props) => {

      let identifier = 'item-' + props.data.item_id;

      let itemVal = props.data.item_id.trim() + '|' + props.data.barcode.trim();

      return (
        <div className="rebalancing-item-container flex"><input className="rebalancing-item-checkbox" value={itemVal} id={identifier} type="checkbox"></input><label htmlFor={identifier}>{props.data.location} - {props.data.barcode}</label></div>
      )
    }

    try {
      return rebalancingItems.map(item => <RebalancingItem key={item} data={item}></RebalancingItem>)
    } catch {}
  }

  const claimRebalancingSubmit = (e) => {
    e.preventDefault();

    const itemIds = [];
    const checkedBoxes = document.querySelectorAll('.rebalancing-item-checkbox:checked');

    if (checkedBoxes.length === 0) {
      return '';
    }

    for (let i = 0; i < checkedBoxes.length; i++) {
      itemIds.push(checkedBoxes[i].value.trim());
    }
  
    let encodedData = 'ids=' + itemIds.join() + "&loc=" + document.querySelector('#rebalancing-pickup-location').value;

    toggleLoadingOverlay();

    postData(conf.baseAPI + "/sierra/sierra-rebalancing-claim.php", encodedData).then((res) => {
      console.log(res);
      toggleLoadingOverlay();

      if (typeof res.statuses !== 'undefined') { // success
        document.querySelector("#rebalacing-claim-overlay .close-overlay").click();
        launchSuccessOverlay(
          "Item(s) claimed", ""
        );
      } else if (typeof res.sessionExpired !== 'undefined') {
        document.querySelector("#rebalacing-claim-overlay .close-overlay").click();
        toggleOverlay('#login-overlay', e);
      } else if (typeof res.error !== 'undefined') { // http error
        document.querySelector("#rebalacing-claim-overlay .close-overlay").click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else { // error from sierra api
        document.querySelector("#rebalacing-claim-overlay .close-overlay").click();
        launchErrorOverlay(res.description);
      }
    });
  }

  const PickupOptions = () => {

    const OptionPickup = (props) => {
      return (
        <option value={props.data.code}>{props.data.name}</option>
      )
    }

    return (
      conf.allHomeBranchLocations.map(item => <OptionPickup key={item.code} data={item}></OptionPickup>)
    )
  }

  

  const RebalancingSelect = () => {

    let defaultPickup = '01';

    try {
      defaultPickup = user.data.session.bpl_catalog.home_library;
    } catch {}

    return (
      <label htmlFor="rebalancing-pickup-location">Pickup Location
        <select defaultValue={defaultPickup} id="rebalancing-pickup-location">
        <PickupOptions></PickupOptions>
        </select>
      </label>
    )
  }

  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="rebalacing-claim-overlay" className='overlay-container'>
      <div className='overlay-body'>
        <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
        <h3>Rebalancing Claim</h3>

        <div className="rebalancing-items-container">
          <RebalancingItemsContainer />
        </div>

        <RebalancingSelect />

        <button onClick={updateRebalancingItems} id="update-rebalancing-items-overlay" className="hide">Update rebalancing item list</button>
        <button onClick={claimRebalancingSubmit} className="default-blue-button full-width">Claim Materials</button>

      </div>
    </div>
  )
}

export default RebalancingClaimOverlay;