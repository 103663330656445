import parseVarfields from "../../functions/parseVarfields";
import { useState } from "react";

const MetadataSection = (props) => {

  const [metadataSectionClasses, setMetadataSectionClasses] = useState('');
  const [metadataBtnClasses, setMetadataBtnClasses] = useState('tab-section-toggle active');

  const [reviewsSectionClasses, setReviewsSectionClasses] = useState('hide');
  const [reviewsBtnClasses, setReviewsBtnClasses] = useState('tab-section-toggle');

  const [marcSectionClasses, setMarcSectionClasses] = useState('hide');
  const [marcBtnClasses, setMarcBtnClasses] = useState('tab-section-toggle');

  const marcSubfieldExcludes = ["1","2","3","4","5","6","7","8","9","0"];

  try {
    const marcFields = parseVarfields(props.data.sm_bib_varfields);
    
    marcFields.sort((a, b) => {
      if (a.tag > b.tag) {
        return 1;
      } else if (a.tag < b.tag) {
        return -1
      } else {
        return 0;
      }
    });

    for (let i = 0; i < marcFields.length; i++) {
      marcFields[i].marcCount = i;
    }
    
    const SubjectsPill = (props) => {
      let link = '';

      try {
        link = '/?catalog=true&subjects=' + encodeURIComponent(props.data);
      } catch {}

      return (
        <a href={link} className="subject-pill-btn">{props.data}</a>
      )
    }

    const SubjectsMap = (props) => {
      return props.data.map((item) => <SubjectsPill key={item} data={item}></SubjectsPill>);
    }

    const Tags = (props) => {
      if (typeof props.data.subjects !== 'undefined') {
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Tags</div>
            <div className="subject-pill-btn-container">
              <SubjectsMap data={props.data.subjects}></SubjectsMap>
            </div>
          </div>
        )
      } else {
        return ''
      }
    }

    const ISBN = (props) => {
      try {
        if (typeof props.data.isbn !== 'undefined') {
          let isbnString = '';
  
          for (let i = 0; i < props.data.isbn.length; i++) {
            if (props.data.isbn[i].indexOf('-') === -1) {
              isbnString += props.data.isbn[i] + ', ';
            }
          }

          isbnString = isbnString.replace(/\, $/, '');

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">ISBNs</div>
              {isbnString}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const Series = (props) => {
      try {
        if (typeof props.data.series !== 'undefined') {
          let contentString = '';
  
          for (let i = 0; i < props.data.series.length; i++) {
            contentString += props.data.series[i] + ' ';
          }

          contentString = contentString.trim();

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Series</div>
              {contentString}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const Contributors = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '245') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag === 'c') {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }

        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Contributor(s)</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const AdditionalContributors = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '700') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag === 'a') {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Additional Contributors</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const CorporateName = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '710') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag === 'a') {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Corporate Contributor/Supplier</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const LanguageNotes = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '546') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Language Notes</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const PhysicalDescription = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '300') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Physical Description</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const Event = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '518') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Event</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const Performer = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '511') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Performer</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    //Creation/Production Credits
    const CreationProductionCredits = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '508') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Creation/Production Credits</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const TargetAudienceMARC = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '521') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Audience</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const NoteContent = (props) => {
      return (
        <div>{props.data}</div>
      )
    }

    const NotesWithLineBreak = (props) => {
      return props.data.map((item) => <NoteContent key={item} data={item}></NoteContent>)
    }

    const Notes = (props) => {
      try {
        const contentArray = [];
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '500') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag.match(/[0-9]/) === null) {
                  contentArray.push(props.data[i].subfields[x].subfieldContent);
                }
            }
          }
        }

        if (contentArray.length === 0) {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Notes</div>
            <NotesWithLineBreak data={contentArray} ></NotesWithLineBreak>
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const LinkDisplay = (props) => {
      const style={marginBottom: '8px'}
      return (
        <div style={style}><a target='_blank' href={props.data.href}>{props.data.text}</a></div>
      )
    }

    const LinksMap = (props) => {
      return props.data.map((item) => <LinkDisplay key={item.href} data={item}></LinkDisplay>)
    }

    const Links = (props) => {
      try {
        const contentArray = [];
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '856') {
            const obj = {href: '', text: ''};
            for (let x = 0; x < props.data[i].subfields.length; x++) {
              if (props.data[i].subfields[x].subfieldTag === 'u') {
                obj.href = props.data[i].subfields[x].subfieldContent;
              } else {
                obj.text += props.data[i].subfields[x].subfieldContent;
              }
            }
            if (obj.text === '') {
              obj.text = obj.href;
            }
            contentArray.push(obj);
          }
        }

        if (contentArray.length === 0) {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Related Links</div>
            <LinksMap data={contentArray} ></LinksMap>
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const FictionStatus = (props) => {
      try {
        if (typeof props.data.fiction_status !== 'undefined') {

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Fiction Status</div>
              {props.data.fiction_status}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const LexileMeasure = (props) => {
      try {
        if (typeof props.data.is_lexile !== 'undefined') {

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Lexile Measure</div>
              {props.data.is_lexile}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const Reviews = (props) => {
      try {

        const GoodreadsLink = (props) => {
          let goodreadsSrc = 'https://www.goodreads.com/search?utf8=%E2%9C%93&query=' + props.data;
          return (
            <div>
              <a target="_blank" className="reviews-link" href={goodreadsSrc}>GoodReads</a>
            </div>
          )
        }

        const NovelistLink = (props) => {
          try {
            if (props.data.author !== '') {
              let src = 'https://search.ebscohost.com/login.aspx?direct=true&AuthType=cookie,ip,cpid&custid=brooklyn&db=neh&tg=UI&site=novp-live&scope=site&bquery=' + props.data.title + " " + props.data.author;
              return (
                <div>
                  <a target="_blank" className="reviews-link" href={src}>Novelist</a>
                </div>
              )
            }
          } catch {}
        }

        const BakerAndTaylorLink = (props) => {
          let src = 'http://contentcafe2.btol.com/ContentCafeClient/ContentCafe.aspx?UserID=BPL&Password=BT0059&Options=N&ItemKey=' + props.data;
          return (
            <div>
              <a target="_blank" className="reviews-link" href={src}>Baker and Taylor</a>
            </div>
          )
        }

        if (typeof props.data.isbn !== 'undefined') {
          // removed <div className="metadata-group-label main-color bold">Reviews</div>
          return (
            <div className="metadata-group-container">
              
              <GoodreadsLink data={props.data.isbn[0]}></GoodreadsLink>
              <NovelistLink data={props.data} />
              <BakerAndTaylorLink data={props.data.isbn[0]} />
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const ElectronicFormats = (props) => {
      try {
        if (typeof props.data.eformat !== 'undefined') {
          let contentString = '';
  
          for (let i = 0; i < props.data.eformat.length; i++) {
            contentString += props.data.eformat[i] + ', ';
          }

          contentString = contentString.replace(/\, $/, '');

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Electronic Formats</div>
              {contentString}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const toggleMetadataSection = (e) => {
      e.preventDefault();      
      if (e.currentTarget.id === 'metadata-section-link') {
        setMetadataSectionClasses('');
        setReviewsSectionClasses('hide');
        setMarcSectionClasses('hide');

        setMetadataBtnClasses('tab-section-toggle active');
        setMarcBtnClasses('tab-section-toggle');
        setReviewsBtnClasses('tab-section-toggle');
      } else if (e.currentTarget.id === 'marc-record-section-link') {
        setMetadataSectionClasses('hide');
        setReviewsSectionClasses('hide');
        setMarcSectionClasses('');

        setMetadataBtnClasses('tab-section-toggle');
        setReviewsBtnClasses('tab-section-toggle');
        setMarcBtnClasses('tab-section-toggle active');
      } else if (e.currentTarget.id === 'reviews-section-link') {
        setMetadataBtnClasses('tab-section-toggle');
        setMarcBtnClasses('tab-section-toggle');
        setReviewsBtnClasses('tab-section-toggle active');

        setMetadataSectionClasses('hide');
        setMarcSectionClasses('hide');
        setReviewsSectionClasses('');
      }
    }

    const MarcGroup = (props) => {
      let content = '';
      try {
        for (let i = 0; i < props.data.subfields.length; i++) {
          if (typeof props.data.subfields[i].subfieldTag !== 'undefined') {
            content += '(' + props.data.subfields[i].subfieldTag + ') ';
          }
          content += props.data.subfields[i].subfieldContent + ' ';
        }
      } catch (e) {console.log(e)}
      
      return (
        <div className="marc-group">
          <div className="marc-tag">{props.data.tag}:</div>
          <div className="marc-content">{content}</div>
        </div>
      )
    }

    const MarcSection = (props) => {
      return props.data.map((item) => <MarcGroup key={item.marcCount} data={item}></MarcGroup>);
    }

    return (
      <div className="metadata-section">
        <div className="tab-flex-container">
          <a href="#" onClick={toggleMetadataSection} id="metadata-section-link" className={metadataBtnClasses}>Metadata</a>
          <a href="#" onClick={toggleMetadataSection} id="reviews-section-link" className={reviewsBtnClasses}>Reviews</a>
          <a href="#" onClick={toggleMetadataSection} id="marc-record-section-link" className={marcBtnClasses}>MARC Record</a>
        </div>
        <div className={metadataSectionClasses} id="metadata-details-container">
          <Tags data={props.data}></Tags>
          
          <ISBN data={props.data}></ISBN>
          <FictionStatus data={props.data}></FictionStatus>
          <Contributors data={marcFields}></Contributors>
          <AdditionalContributors data={marcFields}></AdditionalContributors>
          <Series data={props.data}></Series>
          <CorporateName data={marcFields}></CorporateName>
          <ElectronicFormats data={props.data}></ElectronicFormats>
          <Notes data={marcFields}></Notes>
          <LexileMeasure data={props.data}/>
          <LanguageNotes data={marcFields}/>
          <PhysicalDescription data={marcFields}/>
          <TargetAudienceMARC data={marcFields}/>
          <Event data={marcFields}/>
          <Performer data={marcFields}/>
          <CreationProductionCredits data={marcFields}/>
          <Links data={marcFields}></Links>
        </div>
        <div className={reviewsSectionClasses} id="reviews-container">
          <Reviews data={props.data} />
        </div>
        <div className={marcSectionClasses} id="marc-record-container">
          <MarcSection data={marcFields}></MarcSection>
        </div>
      </div>
    )
  } catch {}
};

export default MetadataSection;